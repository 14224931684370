import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";

const useStyles = makeStyles({
  root: {
    maxWidth: 645,
    background: "rgba(0,0,0,0.5)",
    margin: "20px",
  },
  media: {
    height: 440,
  },
  title: {
    fontFamily: "Fasthand",
    fontWeight: "bold",
    fontSize: "2rem",
    color: "#ddd",
  },
  desc: {
    fontFamily: "Fasthand",
    fontSize: "1.1rem",
    color: "#ddd",
  },
});

export default function ImageCard({ listing }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={listing.imageUrl} />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="h1"
          className={classes.title}
        >
          {listing.Title}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.desc}
        >
          {listing.description}
        </Typography>
      </CardContent>
    </Card>
  );
}
