import React, { useState } from "react";
import "../App.css";
import "../bookdetails.css";
import StarRatings from "react-star-ratings";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useQuery } from "react-query";
import { getBookDetails } from "../services/book-details";
import { getSellerUsername } from "../services/seller-username";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PurchasePopup from "../components/PurchasePopup";
import { genresDict } from "../assets/genres-dict";
import { regionDict } from "../assets/region-dict";
import { deleteListing } from "../services/delete-listing";
import { getBookImage } from "../services/get-image";
import { FixTokens } from "../services/fix-tokens";

const BookDetails = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  function getGenres(genreCodes) {
    var genres = "";
    for (var i = 0; i < genreCodes.length; i++) {
      genres += genresDict[genreCodes[i]] + ", ";
    }
    return genres.slice(0, genres.length - 2);
  }

  function deleteListingButton() {
    deleteListing(id)
    .then((data) => window.location.replace('/my-listings'))
    .catch((error) => {
      if (error.response.status === 401) {
          FixTokens().then((returnedValue) => {
            if(returnedValue) { window.location.reload(false); }
          })
          return <div>Loading... </div>;
      }
        return <div>Something went wrong</div>;
    })
    return;
  }

  const { data, isLoading, isError, error } = useQuery([`getBookDetails`, id], () =>
    getBookDetails(id), 
    {
      retry: 0,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  var seller;
  const sellerUsername = useQuery(
    [`getSellerUsername`, id],
    () => getSellerUsername(id),
    {
      retry: 0,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: sessionStorage.getItem("access") !== null,
    }
  );

  const imageURL = state.imageLink;

  const [modalShow, setModalShow] = useState(false);

  if (isLoading || sellerUsername.isLoading) {
    return <div>Loading...</div>;
  }
  
  if (isError || sellerUsername.isError) {
    if (isError && sellerUsername.isError && (error.response.status === 401) && (sellerUsername.error.response.status === 401)) {
      window.location.replace("/browse-listings");
    }
    return <div>Something went wrong</div>;
  }

  const book = data.data;
  if (sellerUsername.data) {
    if (sellerUsername.data.status != "error") {
      seller = sellerUsername.data.data.seller;
    }
  }

  const handleShow = () => setModalShow(true);

  return (
    <div className="app">
      <PurchasePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        phoneNumber={book.phoneNumber}
        region={regionDict[book.region]}
      />
      <div className="details">
        <div
          className="big-img"
          style={{
            width: "350px",
            height: "450px",
          }}
        >
          <img
            src={imageURL}
            alt="Book Listing Image"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>

        <div className="box">
          <div className="row">
            <h2>{book.title}</h2>
            <span className="price">${book.price}</span>
          </div>

          <div className="book-info">
            <Row>
              <Col>
                <p>
                  <b>Author:</b> {book.author}
                </p>
                <p>
                  <b>Genres:</b> {getGenres(book.genres)}
                </p>
                <p>
                  <b>ISBN:</b> {book.isbn}
                </p>
                <span>
                  <span style={{ verticalAlign: "bottom" }}>
                    <b> Condition: </b>
                  </span>
                  <StarRatings
                    className="star-rating-test"
                    numberOfStars={5}
                    rating={book.condition}
                    starRatedColor="red"
                    name="rating"
                    starDimension="20px"
                    starSpacing="1px"
                  />
                </span>
              </Col>
              <Col>
                <p>
                  <b>Seller Username:</b>{" "}
                  {book.region ? seller : <i>Not signed in.</i>}
                </p>
                <p>
                  <b>Seller Location:</b>{" "}
                  {book.region ? (
                    regionDict[book.region]
                  ) : (
                    <i>Not signed in.</i>
                  )}
                </p>
                <p>
                  <b>Creation Date:</b> {book.creationDate}
                </p>
              </Col>
            </Row>
          </div>

          <Row>
            <Col md>
              <button
                className="purchase-listing-button"
                onClick={() => setModalShow(true)}
                disabled={state.isSeller}
              >
                Purchase Listing
              </button>
            </Col>
            <Col md>
              {state.isSeller || state.isAdmin ? (
                <button
                  className="delete-listing-button"
                  onClick={deleteListingButton}
                >
                  Delete Listing
                </button>
              ) : null}
            </Col>
          </Row>
          <Row>
            <p className="body-grey">
              Note: "Purchase Listing" will reveal seller <b>{seller}</b>'s
              phone number. Contact them to organize the purchase.
            </p>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default BookDetails;
