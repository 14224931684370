import { useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import FloatingLabel from "react-bootstrap-floating-label";
import * as React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useMutation } from "react-query";
import { CreateListingCall } from "../services/create-listing";
import { postPicture } from "../services/post-picture";
import { CheckTokens } from "../services/check-tokens";
import { FixTokens } from "../services/fix-tokens";
import CreateListingWrongPopup from "../components/CreateListingWrongPopup";

export default function CreateListing() {
  const [region, setRegion] = useState([]);
  const [phoneNumber, setPhone_number] = useState("");
  const [isbn, setISBN] = useState("");
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [genres, setGenres] = useState([]);
  const [price, setPrice] = useState(0.0);
  const [edition, setEdition] = useState(0);
  const [condition, setCondition] = useState(1);
  const [picture, setPicture] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const PostPicture = useMutation(
    ({ listing_id, bodyFormData }) => postPicture({ listing_id, bodyFormData }),
    {
      onSuccess: (data) => {
        window.location.replace("/my-listings");
      },
      onError: (error) => {
        window.location.replace("/my-listings");
      },
    }
  );

  const CreateListingMutation = useMutation(
    ({
      region,
      phoneNumber,
      isbn,
      title,
      author,
      genres,
      price,
      edition,
      condition,
    }) =>
      CreateListingCall({
        region,
        phoneNumber,
        isbn,
        title,
        author,
        genres,
        price,
        edition,
        condition,
      }),
    {
      onSuccess: (data) => {
        var bodyFormData = new FormData();
        var listing_id = data.data.id;
        bodyFormData.append("image", picture);
        PostPicture.mutate({ listing_id, bodyFormData });
      },
      onError: (error) => {
        if (error.response.status === 401) {
          FixTokens();
        }
        if (error.response.status === 400) {
          setModalShow(true);
        }
      },
    }
  );

  function Post() {
    if (CheckTokens() === false) {
    } else {
      CreateListingMutation.mutate({
        region,
        phoneNumber,
        isbn,
        title,
        author,
        genres,
        price,
        edition,
        condition,
      });
    }
  }

  const regions_list = [
    { label: "Akkar", value: "AK" },
    { label: "Baalbek", value: "BA" },
    { label: "Beirut", value: "BEY" },
    { label: "Bekaa", value: "BK" },
    { label: "Mount Lebanon", value: "MLB" },
    { label: "Nabatieh", value: "NB" },
    { label: "North Lebanon", value: "NLB" },
    { label: "South Lebanon", value: "SLB" },
  ];

  const labels = {
    1: "Poor",
    2: "Fair",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };

  const genres_list = [
    {
      id: 1,
      label: "Fiction",
      options: [
        { label: "Action", value: "ACTN" },
        { label: "Adventure", value: "ADV" },
        { label: "Children's Books", value: "CHLD" },
        { label: "Fantasy", value: "FN" },
        { label: "Graphic Novel", value: "GRPHC" },
        { label: "Historical Fiction", value: "HISF" },
        { label: "Horror", value: "HRR" },
        { label: "Mystery", value: "MYSTRY" },
        { label: "Romance", value: "ROMNC" },
        { label: "Science Fiction", value: "SCNF" },
        { label: "Thriller & Suspense", value: "THRLLR" },
      ],
    },

    {
      id: 2,
      label: "Non-Fiction",
      options: [
        { label: "Art & Photography", value: "ART" },
        { label: "Biography", value: "BIO" },
        { label: "Food & Drink", value: "FOOD" },
        { label: "Guide", value: "GD" },
        { label: "Health", value: "HLTH" },
        { label: "History", value: "HSTRY" },
        { label: "Humanities & Social Sciences", value: "HUM" },
        { label: "Parenting & Families", value: "PRNT" },
        { label: "Religion & Spirituality", value: "RLGN" },
        { label: "Science & Technology", value: "SCIN" },
        { label: "Travel", value: "TRVL" },
        { label: "University", value: "UNI" },
      ],
    },
  ];

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#E93423",
    },
    "& .MuiRating-iconHover": {
      color: "#E93423",
    },
  });

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  function getExtension(filename) {
    return filename.split(".").pop();
  }

  function onChange(e) {
    if (
      getExtension(e.target.value).toLowerCase() === "png" ||
      getExtension(e.target.value).toLowerCase() === "jpeg" ||
      getExtension(e.target.value).toLowerCase() === "jpg"
    ) {
      setPicture(e.target.files[0]);
    }
  }

  const [value, setValue] = useState(2);
  const [hover, setHover] = useState(-1);

  return (
    <div className="container">
      <h1 className="main-title2">Create Listing</h1>
      <hr className="hor-line"></hr>
      <br></br>
      <h3 className="body-grey">
        Create a book listing by inputting the information below.
      </h3>

      <CreateListingWrongPopup
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      {/* For region */}
      <Row className="g-2 bookforms-textfields">
        <Col md>
          <Select
            className="Region-dropdown"
            name="Region"
            options={regions_list}
            placeholder={"Select Region"}
            minMenuHeight={8}
            onChange={(selectedRegion) => setRegion(selectedRegion.value)}
          />
        </Col>

        {/* For phone_number */}
        <Col md>
          <FloatingLabel
            controlId="floatingInputGrid"
            label="Phone Number"
            onChange={(e) => {
              setPhone_number(e.target.value);
            }}
          >
            <Form.Control required type="number" placeholder="Phone Number" />
          </FloatingLabel>
        </Col>
      </Row>

      {/* For isbn */}
      <Row className="g-2 bookforms-textfields">
        <Col md>
          <FloatingLabel
            controlId="floatingInputGrid"
            label="ISBN"
            onChange={(e) => {
              setISBN(e.target.value);
            }}
          >
            <Form.Control type="number" placeholder="ISBN" />
          </FloatingLabel>
        </Col>

        {/* For title */}
        <Col md>
          <FloatingLabel
            controlId="floatingInputGrid"
            label="Title"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          >
            <Form.Control type="text" placeholder="Title" />
          </FloatingLabel>
        </Col>
      </Row>

      {/* For author */}
      <Row className="g-2 bookforms-textfields">
        <Col md>
          <FloatingLabel
            controlId="floatingInputGrid"
            label="Author"
            onChange={(e) => {
              setAuthor(e.target.value);
            }}
          >
            <Form.Control type="text" placeholder="Author" />
          </FloatingLabel>
        </Col>

        {/* For genres */}
        <Col md>
          <Select
            className="genre-dropdown"
            options={genres_list}
            isMulti
            placeholder={"Select Genre(s)..."}
            minMenuHeight={32}
            onChange={(selectedGenres) =>
              setGenres(selectedGenres.map((entry) => entry.value))
            }
          />
        </Col>
      </Row>

      {/* For price */}
      <Row className="g-2 bookforms-textfields">
        <Col md>
          <FloatingLabel
            controlId="floatingInputGrid"
            label="Price"
            onChange={(e) => {
              setPrice(e.target.value);
            }}
          >
            <Form.Control type="number" placeholder="Price" />
          </FloatingLabel>
        </Col>

        {/* For edition */}
        <Col md>
          <FloatingLabel
            controlId="floatingInputGrid"
            label="Edition"
            onChange={(e) => {
              setEdition(e.target.value);
            }}
          >
            <Form.Control type="number" placeholder="Edition" />
          </FloatingLabel>
        </Col>
      </Row>

      {/* For condition */}
      <Box
        component="span"
        sx={{
          "& > legend": { mt: 5 },
        }}
      >
        <Typography component="legend">Condition:</Typography>
        <StyledRating
          name="Condition"
          value={value}
          precision={1}
          getLabelText={getLabelText}
          onChange={(event, newValue) => {
            setValue(newValue);
            setCondition(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
        {value !== null && (
          <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
        )}
      </Box>

      <br></br>
      <br></br>
      <br></br>
      <form>
        <div>
          <input
            type="file"
            name="img"
            accept="image/png, image/jpeg"
            onChange={(e) => onChange(e)}
          />
        </div>
        <br></br>
        <br></br>
      </form>

      <br></br>
      <br></br>

      <Button
        className="post-button"
        type="submit"
        size="lg"
        onClick={() => Post()}
      >
        Post
      </Button>
    </div>
  );
}
