import axios from "axios";

const FixTokens = async () => {

    const refresh = sessionStorage.getItem("refresh");
    try {
      var response = await axios.post(`https://api.goodbuy-books.ml/token/refresh/`, {
          refresh: refresh,
      })
      sessionStorage.setItem("access", response.data.access);
      return true;
    }
    catch(error) {

      sessionStorage.removeItem("access");
      sessionStorage.removeItem("refresh");

      if(sessionStorage.getItem("MicrosoftLink") === null) {

          axios.get(`https://api.goodbuy-books.ml/login/microsoft/`)
          .then((data) => {})
          .catch((error) => {
            if(error.response.status < 400) {             
              sessionStorage.setItem("MicrosoftLink", error.response.data);
              window.location.replace(sessionStorage.getItem("MicrosoftLink"));
            }
          })
          
        }
      else{
        window.location.replace(sessionStorage.getItem("MicrosoftLink"));
        return false;
      }
      return true;
    }
};

export {FixTokens}