const genresDict = {
  ACTN: "Action",
  ADV: "Adventure",
  CHLD: "Children's Books",
  FN: "Fantasy",
  GRPHC: "Graphic Novel",
  HISF: "Historical Fiction",
  HRR: "Horror",
  MYSTRY: "Mystery",
  ROMNC: "Romance",
  SCNF: "Science Fiction",
  THRLLR: "Thriller & Suspense",
  ART: "Art & Photography",
  BIO: "Biography",
  FOOD: "Food & Drink",
  GD: "Guide",
  HLTH: "Health",
  HSTRY: "History",
  HUM: "Humanities & Social Sciences",
  PRNT: "Parenting & Families",
  RLGN: "Religion & Spirituality",
  SCIN: "Science & Technology",
  TRVL: "Travek",
  UNI: "University",
};

module.exports = { genresDict };
