import axios from "../api";

export const getMyListings = async (myUsername) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("access"),
  };

  return await axios.get(`/book-listings/?seller=${myUsername}`, {
    headers: headers,
  });
};
