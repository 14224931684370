import React from "react";
import { useState } from "react";
import ProductList from "../components/ProductList";
import BookForms from "../components/BookForms";
import "../bookforms.css";
import { useQuery} from "react-query";
import { getBooks } from "../services/book-listings";
import { getMyUsername } from "../services/get-my-username";
import { FixTokens } from "../services/fix-tokens";



const BrowseListings = () => {
  const { data, isLoading, isError, error } = useQuery("getBooks", getBooks, {
    retry: 0,
  });
  
  var isAdmin = false;

  const response = useQuery("getMyUsername", () => getMyUsername(), {
    enabled: (sessionStorage.getItem("access") !== null || sessionStorage.getItem("refresh") !== null) ,
    retry :  0,
  });

  const [filtered, setFiltered] = useState(null);

  if (isLoading || response.isLoading) {
    return <div>Loading...</div>;
  }
  if (isError || response.isError) {
    if (isError && response.isError && (error.response.status === 401) && (response.error.response.status === 401)) {
      FixTokens().then((returnedValue) => {
        if(returnedValue){ window.location.reload(false); }
      })
      return <div>Loading... </div>;
    }
    return <div>Something went wrong</div>;
  }

  if (response.data) {
    isAdmin = response.data.data.is_staff;
  }

  return (
    <div className="container">
      <h1 className="main-title2">Browse Listings</h1>
      <hr className="hor-line"></hr>
      <br></br>
      <BookForms setFiltered={setFiltered} unfiltered={data.data.results} />

      {filtered != null ? (
        <>
          <ProductList
            data={filtered}
            fromMyListings={false}
            isAdmin={isAdmin}
          />
        </>
      ) : (
        <>
          <ProductList
            data={data.data.results}
            fromMyListings={false}
            isAdmin={isAdmin}
          />
        </>
      )}
    </div>
  );
};

export default BrowseListings;
