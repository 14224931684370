import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";

{
  <style>
    @import
    url('https://fonts.googleapis.com/css2?family=Fasthand&family=Merriweather:wght@300&display=swap');
  </style>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  appbarTitle: {
    display: "flex",
    textAlign: "flex-start",
    flexGrow: "1",
    color: "white",
    fontFamily: "Fasthand",
  },
  icon: {
    color: "#fff",
    fontSize: "2rem",
  },
  colorText: {
    color: "#0B3983",
  },
  title: {
    color: "#fff",
    fontSize: "3rem",
    textAlign: "center",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
  },
  container: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 300,
    left: 0,
    right: 0,
    botton: 0,
  },

  description: {
    flex: 3,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: "1",
    color: "#002141",
    fontFamily: "Fasthand",
    position: "absolute",
    top: 500,
    left: 0,
    right: 0,
    botton: 0,
    height: 10,
    textShadow:
      "-1px -0.1px 0 #000, 0.1px -0.1px 0 #000, -0.1px 0.1px 0 #000, 0.1px 0.1px 0 #000",
  },
}));

export default function Header() {
  const classes = new useStyles();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(true);
  });
  return (
    <div className={classes.root}>
      <Collapse
        in={checked}
        {...(checked ? { timeout: 1000 } : {})}
        collapseheight={50}
      >
        <div className={classes.container}>
          <h1 className={classes.title}>
            Welcome to <br /> GoodBuy
            <span className={classes.colorText}>Books</span>
          </h1>
        </div>

        <h1 className={classes.description}>
          Connecting buyers and sellers <br></br>
          of used books in Lebanon
        </h1>
      </Collapse>
    </div>
  );
}
