import React from "react";
import { useState, useEffect } from "react";
import ProductList from "../components/ProductList";
import BookForms from "../components/BookForms";
import "../bookforms.css";
import { useQuery } from "react-query";
import { getMyListings } from "../services/my-listings";
import { getMyUsername } from "../services/get-my-username";
import { FixTokens } from "../services/fix-tokens";

const MyListings = () => {

  const myUsername = useQuery("getMyUsername", getMyUsername, 
  {
    retry: 0,
  });

  const { data, isLoading, isError } = useQuery(
    "getMyListings",
    () => getMyListings(myUsername.data.data.user),
    {
      enabled: !myUsername.isLoading && !myUsername.isError,
      retry: 0,
    }
  );

  const [filtered, setFiltered] = useState(null);

  if (myUsername.isLoading || isLoading) {
    return <div>Loading...</div>;
  }

  if (myUsername.isError || isError) {
    if(myUsername.error.response.status === 401) {
      FixTokens().then((returnedValue) => {
        if(returnedValue) { window.location.reload(false); }
      })
      return <div>Loading... </div>;
    }
    return <div>Something went wrong</div>;
  }

  return (
    <div className="container">
      <h1 className="main-title2">My Listings</h1>
      <hr className="hor-line"></hr>
      <br></br>
      <BookForms setFiltered={setFiltered} unfiltered={data.data.results} />

      {filtered != null ? (
        <>
          <ProductList data={filtered} fromMyListings={true} />
        </>
      ) : (
        <>
          <ProductList data={data.data.results} fromMyListings={true} />
        </>
      )}
    </div>
  );
};

export default MyListings;
