import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const CreateListingWrongPopup = ({show, onHide}) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={show}
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Listing
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
            <p>
              Could not create the listing. Please ensure all fields are filled in the correct format.
            </p>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>I Understand</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateListingWrongPopup;
