import axios from "axios";

const CheckTokens = () => {

  if ((sessionStorage.getItem("access") === null) &&  (sessionStorage.getItem("refresh") === null)) {

    if(sessionStorage.getItem("MicrosoftLink") === null) {

      axios.get(`https://api.goodbuy-books.ml/login/microsoft/`)
      .then((data) => {})
      .catch((error) => {
        if(error.response.status < 400) {             
          sessionStorage.setItem("MicrosoftLink", error.response.data);
          window.location.replace(sessionStorage.getItem("MicrosoftLink"));
        }
      })

    }
    else {
      window.location.replace(sessionStorage.getItem("MicrosoftLink"));
    }

    return false;
  }
    
  return true
}

export {CheckTokens}