import React from "react";
import { makeStyles } from '@mui/styles';
import ImageCard from "./ImagCard";
import listings from "../assets/data"

const useStyles = makeStyles((theme) => ({
    root:{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

export default function() {
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <ImageCard listing={listings[1]}/>
            <ImageCard listing={listings[2]}/>
        </div>
    )
}