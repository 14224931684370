import axios from "../api";

export const deleteListing = async (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("access"),
  };
  return await axios.delete(`/book-listings/${id}/`, {
    headers: headers,
  });
};
