import axios from "../api";

export const getBookDetails = async (bookid) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("access"),
  };

  if (sessionStorage.getItem("access") != null) {
    return await axios.get(`book-listings/${bookid}/`, {
      headers: headers,
    });
  }

  return await axios.get(`book-listings/${bookid}/`);
};
