import axios from "../api";

export const getBooks = async () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("access"),
  };

  if ( (sessionStorage.getItem("access") != null) || (sessionStorage.getItem("refresh") != null) ) {
    return await axios.get("/book-listings/", {
      headers: headers,
    });
  }
  return await axios.get("/book-listings/");
};
