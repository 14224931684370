import CssBaseline from "@mui/material/CssBaseline";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import BookSuggestion from "../components/BookSuggestion.js";
import Header from "../components/Header";
import { useQuery } from "react-query";
import { getSignIn } from "../components/SignInButton";
import Button from "react-bootstrap/Button";
import { BorderColor } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/bgHome.jpg"})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },

  SignButton: {
    flex: 0.1,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    position: "absolute",
    top: 650,
    left: 0,
    right: 0,
    botton: 0,
    height: 10,
  },
}));

export default function Home() {
  const classes = useStyles();
  const { data, isError, isSuccess, isLoading, error } = useQuery(
    "getSignIn",
    getSignIn,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );

  const isLoggedIn =
    sessionStorage.getItem("access") !== null ||
    sessionStorage.getItem("refresh") !== null;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    const responseId = data.status;
    if (responseId !== 301) {
      return <div>Something went wrong.</div>;
    }
    sessionStorage.setItem("MicrosoftLink", data.data);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />

      {!isLoggedIn ? (
        <div className={classes.SignButton}>
          <a href={sessionStorage.getItem("MicrosoftLink")}>
            <Button
              size="lg"
              style={{
                backgroundColor: "#002141",
                borderColor: "black",
                width: "150px",
                height: "50px",
              }}
            >
              Sign In
            </Button>
          </a>
        </div>
      ) : null}

      <br></br>
      <br></br>
    </div>
  );
}
