import axios from "../api";

export const getMyUsername = async () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("access"),
  };
  return await axios.get(`whoami/`, {
    headers: headers,
  });
};
