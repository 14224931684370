import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const PurchasePopup = ({ show, onHide, phoneNumber, region }) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={show}
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Purchase Listing
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {region ? (
          <>
            <p>
              Please contact the seller using their phone number to conduct the
              sale:
            </p>
            <p>
              <b>{phoneNumber}</b>{" "}
            </p>
            <p>
              As a reminder, they reside in <b>{region}</b>.
            </p>
          </>
        ) : (
          <i> Please sign in to purchase a listing.</i>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>I Understand</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PurchasePopup;
