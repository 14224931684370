import axios from "../api";

export const getSellerUsername = async (bookid) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("access"),
  };

  return await axios.get(`book-listings/${bookid}/seller/`, {
    headers: headers,
  });
};
