import React, { Component } from "react";
import ProductRow from "./ProductRow";
import "../productlist.css";

const ProductList = ({ data, fromMyListings, isAdmin }) => {
  return (
    <div className="container main-content">
      {data.map((bookListing) => (
        <ProductRow
          key={bookListing.id}
          bookListing={bookListing}
          fromMyListings={fromMyListings}
          isAdmin={isAdmin}
        />
      ))}
    </div>
  );
};

export default ProductList;
