import React from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import StarRatings from "react-star-ratings";
import "../product.css";
import { genresDict } from "../assets/genres-dict";
import { regionDict } from "../assets/region-dict";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getBookImage } from "../services/get-image";
import { useQuery } from "react-query";

const ProductRow = ({ bookListing, fromMyListings, isAdmin }) => {
  function getGenres(genreCodes) {
    var genres = "";
    for (var i = 0; i < genreCodes.length; i++) {
      genres += genresDict[genreCodes[i]] + ", ";
    }
    return genres.slice(0, genres.length - 2);
  }

  const navigate = useNavigate();

  const image = useQuery([`getBookImage`, bookListing.id], () =>
    getBookImage(bookListing.id)
  );

  if (image.isLoading) {
    return;
  }
  if (image.isError) {
    return;
  }

  var imageURL;
  if (image.data.data[0]) {
    imageURL = image.data.data[0];
  } else {
    imageURL = "https://i.ibb.co/M1rZxTH/defaultimage.jpg";
  }

  const link = `/book-id=${bookListing.id}`;

  return (
    <div
      className="row product product-link"
      onClick={() =>
        navigate(`/book-id=${bookListing.id}`, {
          state: {
            isSeller: fromMyListings,
            imageLink: imageURL,
            isAdmin: isAdmin,
          },
        })
      }
    >
      <div
        className="col-md-2"
        style={{ width: "200px", height: "250px", paddingTop: "15px" }}
      >
        <a href={link}>
          <img
            src={imageURL}
            alt="Book Listing Image"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </a>
      </div>
      <div className="col-md-8 product-detail">
        <h4 className="m-0">
          {bookListing.title.length > 48
            ? `${bookListing.title.substring(0, 48)}...`
            : bookListing.title}
        </h4>
        <br></br>
        <div className="mb-3">
          <b>Author:</b> {bookListing.author}
        </div>
        <div className="my-3">
          <b>Genres:</b> {getGenres(bookListing.genres)}
        </div>
        <div className="my-3">
          <span>
            <span style={{ verticalAlign: "bottom" }}>
              <b> Condition: </b>
            </span>
            <StarRatings
              className="star-rating-test"
              numberOfStars={5}
              rating={bookListing.condition}
              starRatedColor="red"
              name="rating"
              starDimension="20px"
              starSpacing="1px"
            />
          </span>
        </div>
        <div className="my-3">
          <b>Seller Location:</b>{" "}
          {bookListing.region ? (
            regionDict[bookListing.region]
          ) : (
            <i>Not signed in.</i>
          )}
        </div>
        <div className="mt-3">
          <b>Posted on:</b> {bookListing.creationDate}
        </div>
      </div>
      <div className="col-md-2 product-price">${bookListing.price}</div>
    </div>
  );
};

export default ProductRow;
