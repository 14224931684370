import axios from "../api";

export const postPicture = async ({ listing_id, bodyFormData }) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + sessionStorage.getItem("access"),
  };
  return await axios.post(`/images/?listing_id=${listing_id}`, bodyFormData, {
    headers: headers,
  });
};
