import React from "react";
import { useState } from "react";
import FloatingLabel from "react-bootstrap-floating-label";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SearchButton from "../components/SearchButton";
import Select from "react-select";
import "../bookforms.css";
import { genresDict } from "../assets/genres-dict";

const genres_list = [
  {
    id: 1,
    label: "Fiction",
    options: [
      { label: "Action", value: "ACTN" },
      { label: "Adventure", value: "ADV" },
      { label: "Children's Books", value: "CHLD" },
      { label: "Fantasy", value: "FN" },
      { label: "Graphic Novel", value: "GRPHC" },
      { label: "Historical Fiction", value: "HISF" },
      { label: "Horror", value: "HRR" },
      { label: "Mystery", value: "MYSTRY" },
      { label: "Romance", value: "ROMNC" },
      { label: "Science Fiction", value: "SCNF" },
      { label: "Thriller & Suspense", value: "THRLLR" },
    ],
  },

  {
    id: 2,
    label: "Non-Fiction",
    options: [
      { label: "Art & Photography", value: "ART" },
      { label: "Biography", value: "BIO" },
      { label: "Food & Drink", value: "FOOD" },
      { label: "Guide", value: "GD" },
      { label: "Health", value: "HLTH" },
      { label: "History", value: "HSTRY" },
      { label: "Humanities & Social Sciences", value: "HUM" },
      { label: "Parenting & Families", value: "PRNT" },
      { label: "Religion & Spirituality", value: "RLGN" },
      { label: "Science & Technology", value: "SCIN" },
      { label: "Travel", value: "TRVL" },
      { label: "University", value: "UNI" },
    ],
  },
];

const BookForms = ({ unfiltered, setFiltered }) => {
  const [ISBN, setISBN] = useState("");
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [genres, setGenres] = useState([]);
  const [searchRes, setSearchRes] = useState(
    <h3 className="sub-title">All listings:</h3>
  );

  function extractBookInfo() {
    if (ISBN == "" && title == "" && author == "" && genres.length == 0) {
      setSearchRes(<h3 className="sub-title">All listings:</h3>);
      setFiltered(null);
      return;
    }

    var filtered = [...unfiltered];

    if (ISBN !== "") {
      setSearchRes(
        <h3 className="sub-title">Search results for ISBN {ISBN}:</h3>
      );
      filtered = filtered.filter(function (el) {
        return el.isbn.includes(ISBN);
      });
    } else {
      if (title !== "") {
        setSearchRes(
          <h3 className="sub-title">Search results for "{title}":</h3>
        );
        filtered = filtered.filter(function (el) {
          return el.title.toLowerCase().includes(title.toLowerCase());
        });
      }
      if (author !== "") {
        if (title == "") {
          setSearchRes(
            <h3 className="sub-title">Search results by "{author}":</h3>
          );
        }
        filtered = filtered.filter(function (el) {
          return el.author.toLowerCase().includes(author.toLowerCase());
        });
      }
      if (genres.length !== 0) {
        if (title == "" && author == "") {
          setSearchRes(
            <h3 className="sub-title">Search results with selected genres:</h3>
          );
        }
        filtered = filtered.filter(function (el) {
          for (const bookGenres of el.genres) {
            if (genres.includes(bookGenres)) {
              return true;
            }
          }
          return false;
        });
      }
    }

    setFiltered(filtered);
  }

  return (
    <>
      <h3 className="body-grey">
        Search for a specific book listing by inputting the information below.
      </h3>
      <Row className="g-2 bookforms-textfields">
        <Col md>
          <FloatingLabel
            controlId="floatingInputGrid"
            label="ISBN"
            onChange={(e) => {
              setISBN(e.target.value);
            }}
          >
            <Form.Control type="number" placeholder="ISBN" />
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="g-2 bookforms-textfields">
        <Col md>
          <FloatingLabel
            controlId="floatingInputGrid"
            label="Title"
            onChange={(e) => setTitle(e.target.value)}
            disabled={ISBN !== ""}
          >
            <Form.Control
              disabled={ISBN !== ""}
              type="text"
              placeholder="Harry Potter and the Philosopher's Stone"
            />
          </FloatingLabel>
        </Col>
        <Col md>
          <FloatingLabel
            controlId="floatingInputGrid"
            label="Author"
            onChange={(e) => setAuthor(e.target.value)}
          >
            <Form.Control type="text" placeholder="J. K. Rowling" />
          </FloatingLabel>
        </Col>
        <Col md>
          <Select
            className="genre-dropdown"
            options={genres_list}
            isMulti
            placeholder={"Select Genre(s)..."}
            minMenuHeight={32}
            onChange={(selectedGenres) =>
              setGenres(selectedGenres.map((entry) => entry.value))
            }
          />
        </Col>
      </Row>
      <br></br>
      <Row>
        <SearchButton className="search-button" onClick={extractBookInfo} />
      </Row>
      <br></br>
      <br></br>
      {/* {searchResultsDescription()} */}
      {searchRes}
    </>
  );
};

export default BookForms;
