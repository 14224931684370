import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import BrowseListings from "./pages/BrowseListings";
import CreateListing from "./pages/CreateListing";
import MyListings from "./pages/MyListings";
import BookDetails from "./pages/BookDetails";
import SigningIn from "./services/signing-in";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

function RedirectToSignIn() {
  window.location.replace(sessionStorage.getItem("MicrosoftLink"));
  return null;
}

function App() {
  return (
    <>
      <NavBar />
      <Router>
        <Routes>
          <Route path="/token/microsoft/i_got_code/*" element={<SigningIn />} />
          <Route path="/" element={<Home />} />
          <Route path="/browse-listings" element={<BrowseListings />} />
          <Route
            path="/create-listing"
            element={
              (sessionStorage.getItem("access") != null || sessionStorage.getItem("refresh") != null) ?  (
                <CreateListing />
              ) : sessionStorage.getItem("MicrosoftLink") == null ? (
                <Home />
              ) : (
                <RedirectToSignIn />
              )
            }
          />
          <Route
            path="/my-listings"
            element={
              (sessionStorage.getItem("access") != null || sessionStorage.getItem("refresh") != null) ? (
                <MyListings />
              ) : sessionStorage.getItem("MicrosoftLink") == null ? (
                <Home />
              ) : (
                <RedirectToSignIn />
              )
            }
          />

          <Route path="/book-id=:id" element={<BookDetails />} />
          {/* <Link to={{ pathname: MicrosoftLink, query:{}}} target="_blank"> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
