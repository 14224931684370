const regionDict = {
  AK: "Akkar",
  BA: "Baalbek",
  BEY: "Beirut",
  BK: "Bekaa",
  MLB: "Mount Lebanon",
  NB: "Nabatieh",
  NLB: "North Lebanon",
  SLB: "South Lebanon",
};

module.exports = { regionDict };
