import Button from "react-bootstrap/Button";
import "../bookforms.css";

function BlockExample({ onClick }) {
  return (
    <div className="d-grid gap-2">
      <Button className="search-button" size="lg" onClick={onClick}>
        Search Listings
      </Button>
    </div>
  );
}

export default BlockExample;
