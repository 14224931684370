import axios from  '../api';

export const getSignIn = async () => {
  let response;
  try{
    response = await axios.get(`/login/microsoft/`);
    return {
      status: response.status,
      data: response.data
    }
  }catch(err){
    if(err.response.status < 400){
      return {
        status: err.response.status,
        data: err.response.data
      }
    }
    throw err;
  }
};

