import React from "react";
import { useSearchParams } from "react-router-dom";
import { getTokens } from "./getting-tokens";
import { useQuery } from "react-query";

export default function SigningIn() {
  const [searchParams] = useSearchParams();
  sessionStorage.setItem("code", searchParams.get("code"));
  sessionStorage.setItem("session_state", searchParams.get("session_state"));
  const { data, isError, isLoading } = useQuery(
    [
      "getTokens",
      sessionStorage.getItem("code"),
      sessionStorage.getItem("session_state"),
    ],
    () =>
      getTokens(
        sessionStorage.getItem("code"),
        sessionStorage.getItem("session_state")
      )
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>500</div>;
  }
  sessionStorage.setItem("access", data.data.access);
  sessionStorage.setItem("refresh", data.data.refresh);
  window.location.replace("/");
  return;
}
