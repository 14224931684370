import axios from "../api";

export const CreateListingCall = async ({ region, phoneNumber, isbn, title, author, genres, price, edition, condition }) => {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("access")
    }

    return await axios.post('/book-listings/', {
      'region': region,
      'phoneNumber': phoneNumber, 
      'isbn': isbn,
      'title': title,
      'author': author,
      'genres': genres,
      'price': price,
      'edition': edition,
      'condition': condition,
    }, {
      headers: headers,
    });
  };